<template>
    <el-container class="container">
        <div class="nei_box">
            <div class="box_one">
                <div class="title_flex">
                    <div class="line"></div>
                    <div class="text">成果发布</div>
                    <div class="line"></div>
                </div>
                <div class="text_box">
                    <span class="num_yan">1</span><span class="num_text">成果基本信息</span>
                </div>
                <div class="title">
                   <div class="form_box">
                       <el-form  :rules="rules" :label-position="labelPosition" label-width="85px" ref="formLabelAlign" :model="formLabelAlign">
                           <el-form-item label="成果名称">
                               <el-input v-model="formLabelAlign.name" placeholder="请输入"></el-input>
                           </el-form-item>
                           <el-form-item label="现有持有人">
                               <el-input v-model="formLabelAlign.holder" placeholder="请输入"></el-input>
                           </el-form-item>
                           <el-form-item label="手机" prop="phone">
                               <el-input     v-model="formLabelAlign.phone" placeholder="请输入"></el-input>
                           </el-form-item>
                           <el-form-item label="工作单位">
                               <el-input v-model="formLabelAlign.work" placeholder="请输入"></el-input>
                           </el-form-item>
                           <el-form-item label="行业分类" prop="address">
                                <!-- <el-input v-model="formLabelAlign.industry" placeholder="请输入"></el-input> -->
                               <el-select v-model="formLabelAlign.industry" placeholder="请选择行业分类">
                                   <el-option
                                           v-for="item in type"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                   </el-option>
                               </el-select>
                           </el-form-item>
                           <el-form-item label="战略性新兴产业分类">
                                 <!-- <el-input v-model="formLabelAlign.xtype" placeholder="请输入"></el-input> -->
                               <el-select v-model="formLabelAlign.xtype" placeholder="请选择战略性新兴产业分类">
                                   <el-option
                                           v-for="item in  typezhan"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                   </el-option>
                               </el-select>
                           </el-form-item>
                           <el-form-item label="成熟度">
                               <el-select v-model="formLabelAlign.maturity" placeholder="请选择成熟度">
                                   <el-option
                                           v-for="item in  chengtype"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                   </el-option>
                               </el-select>
                           </el-form-item>
                           <el-form-item label="技术领域">
                               <el-select v-model="formLabelAlign.territory" placeholder="请选择技术领域">
                                   <el-option
                                           v-for="item in  ling_type"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                   </el-option>
                               </el-select>
                           </el-form-item>
                           <el-form-item label="合作方式">
                               <el-select v-model="formLabelAlign.hmethod" placeholder="请选择合作方式">
                                   <el-option
                                           v-for="item in  hmethoda"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                   </el-option>
                               </el-select>
                           </el-form-item>

                           <!--<el-form-item label="技术领域">-->
                               <!--<el-input v-model="formLabelAlign.territory" placeholder="请输入"></el-input>-->
                           <!--</el-form-item>-->

                           <el-form-item label="项目来源">
                               <el-input v-model="formLabelAlign.projecty" placeholder="请输入"></el-input>
                           </el-form-item>
                           <el-form-item label="项目所处阶段">
                               <el-input v-model="formLabelAlign.projects" placeholder="请输入"></el-input>
                                 <!-- <el-select v-model="formLabelAlign.projects" placeholder="请选择项目所处阶段">
                                   <el-option
                                           v-for="item in  chengtype"
                                           :key="item.id"
                                           :label="item.name"
                                           :value="item.id">
                                   </el-option>
                               </el-select> -->
                           </el-form-item>
                       </el-form>
                   </div>
               </div>

                <div class="text_box_two">
                    <span class="num_yan">2</span><span class="num_text">项目说明信息</span>
                </div>
                <div class="title">
                    <div class="form_box">
                        <el-form ref="formLabelAlign" :rules="rules" :label-position="labelPosition" :model="formLabelAlign" label-width="80px">
                            <el-form-item label="项目简介 " prop="project_say">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.project_say"></el-input>
                            </el-form-item>
                            <el-form-item label="技术/产品创新性" prop="">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.technology"></el-input>
                            </el-form-item>
                            <el-form-item label="知识产权情况" prop="">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.knowledge"></el-input>
                            </el-form-item>

                            <el-form-item label="行业情况" prop="">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.situation"></el-input>
                            </el-form-item>
                            <el-form-item label="市场情况 " prop="">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.market"></el-input>
                            </el-form-item>

                            <el-form-item label="项目团队情况" prop="">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.team"></el-input>
                            </el-form-item>

                            <el-form-item label="合作意向" prop="">
                                <el-input type="textarea"   placeholder="多行输入" v-model="formLabelAlign.cooperation"></el-input>
                            </el-form-item>
                            <el-form-item label="上传图片" class="header" prop="">
                                <el-upload
                                        :on-change="fn"
                                        action=""
                                        list-type="picture-card"
                                        :on-preview="handlePictureCardPreviewG"
                                        :on-remove="handleRemoveG"
                                        :auto-upload="false"
                                        accept="image/*"
                                        :class="{ hide: hideUploadG }"
                                        class="avatar-uploader">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                                <el-dialog :visible.sync="dialogVisible">
                                    <img width="100%" :src="dialogImageUrl" alt="" />
                                </el-dialog>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">发布</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </el-container>
</template>

<script>
    import { handleCompressImg } from "../../../api";
    import {hmethods,achievements,industrys,zhan_type,maturity,territorys} from "../../common/js/api";
    export default {
        name: "Sucessresult",
        data() {
            return {
                rules: {
                    phone: [
                        { required: true, message: '请输入手机号', trigger: 'change' },
                        { pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式',  trigger: 'blur' }
                    ],
                },
                dialogVisible: false,
                dialogImageUrl: "",
                hideUploadG: false,
                labelPosition: 'left',
                formLabelAlign: {
                    maturity:"",
                    xtype:"",
                    member_id:JSON.parse(localStorage.getItem('user')).id,
                    name	:"",
                    holder	:"",
                    phone	:"",
                    work	:"",
                    industry	:"",
                    projecty	:"",
                    territory	:"",
                    projects	:"",
                    project_say	:"",
                    technology	:"",
                    knowledge	:"",
                    situation	:"",
                    market	:"",
                    team	:"",
                    cooperation:"",
                    img:"",
                    hmethod:""
                },
                type:[],
                typezhan:[],
                chengtype:[],
                ling_type:[],
                hmethoda:[]
        }
        },
        created(){
			if(!localStorage.getItem("user")){
					  this.$router.push({
					    path: "/login",
					  });
			}
           this.hang_type()
            this.zahnlue()
            this.chengshu()
            this.cheng_ling()
            this.he_zuo()
        },
        methods: {
            he_zuo(){
                hmethods({}).then((res) => {
                    this.hmethoda=res.data
                }).catch((err) => {
                });
            },
            fn(file, fileList) {
                this.hideUploadG = fileList.length >= 1;
                handleCompressImg(file.raw).then((result) => {
                    console.log(result);
                    this.UploadImg(result, 1);
                });
            },
            //将图片地址上传
            async UploadImg(file){
                let formData = new FormData()
                formData.append('file',file)
                await this.$axios.post('http://hout.kehui.cloud/api/demand/uploadImg',formData,{headers:{'Content-Type':'"multipart/form-data"'}})
                    .then(res=>{
                        console.log(res.data.data);
                        this.formLabelAlign.img=res.data.data
                    })
            },
            handleRemoveG(file, fileList) {
                this.hideUploadG = fileList.length >= 1;
            },
            handlePictureCardPreviewG(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            cheng_ling(){
                territorys({}).then((res) => {
                    this.ling_type=res.data
                }).catch((err) => {
                });
            },
            chengshu(){
                maturity({}).then((res) => {
                    this.chengtype=res.data
                }).catch((err) => {
                });
            },
            zahnlue(){
               zhan_type({}).then((res) => {
                   this.typezhan=res.data
               }).catch((err) => {
               });
            },
            onSubmit() {
                this.ji_type()
            },
            hang_type(){
                industrys({}).then((res) => {
                    this.type=res.data
                }).catch((err) => {
                });
            },
            ji_type(){
                achievements({
                    data:this.formLabelAlign,
                }).then((res) => {
                    if(res.code==200){
                        this.$message({
                            message:res.msg,
                            type: 'success'
                        });
                        this.$router.push({
                            path:"/organizati"
                        })
                    }else if(res.code==201){
                        this.$message({
                            message:res.msg,
                            type: 'success'
                        });
                    }

                }).catch((err) => {
                });
            },

        }
    }
</script>

<style scoped lang="less">
    /deep/.el-button{
        margin-left: -390px;
		font-size: 18px;
    }
    /deep/.el-form-item__label:before{
        content:unset !important;
    }
  /deep/.el-upload-list--picture-card{
      margin: -160px;
  }
    /deep/.hide .el-upload--picture-card {
        display: none;
    }
     /* 笔记本电脑的字体大小 */
    @media screen and (min-width: 768px) and (max-width: 1116px) {
        .nei_box {
              overflow: hidden;
              margin: 30px auto;
              width: 1200px;
            //   height: 2100px !important;
              line-height: 20px;
              border-radius: 5px;
              background-color: rgba(56, 146, 235, 100);
              text-align: center;
              border: 1px solid rgba(255, 255, 255, 100);
              .box_one {
                  width: 1130px;
                   height: 1970px;
                  background-color: #fff;
                  line-height: 20px;
                  margin: 50px auto;
                  text-align: center;
                  border: 1px solid rgba(255, 255, 255, 100);
              }
          }
    }
     @media screen and (min-width: 1200px) and (max-width: 1400px) {
         .nei_box {
             overflow: hidden;
             margin: 30px auto;
             width: 1200px;
            //  height: 2000px !important;
             line-height: 20px;
             border-radius: 5px;
             background-color: rgba(56, 146, 235, 100);
             text-align: center;
             border: 1px solid rgba(255, 255, 255, 100);
             .box_one {
                 width: 1130px;
                height: 1970px;
                 background-color: #fff;
                 line-height: 20px;
                 margin: 50px auto;
                 text-align: center;
                 border: 1px solid rgba(255, 255, 255, 100);
             }
         }
     }
    /*!* 台式电脑显示器屏幕字体大小 *!*/
     @media screen and (min-width: 1400px) and (max-width: 1700px) {
         .nei_box {
             overflow: hidden;
             margin: 30px auto;
             width: 1200px;
            //  height: 1950px !important;
             line-height: 20px;
             border-radius: 5px;
             background-color: rgba(56, 146, 235, 100);
             text-align: center;
             border: 1px solid rgba(255, 255, 255, 100);
             .box_one {
                 width: 1130px;
                 height: 1970px;
                 background-color: #fff;
                 line-height: 20px;
                 margin: 50px auto;
                 text-align: center;
                 border: 1px solid rgba(255, 255, 255, 100);
             }
         }
     }
     @media screen and (min-width: 1700px) and (max-width: 1900px) {
         .nei_box {
             overflow: hidden;
             margin: 30px auto;
             width: 1200px;
             height: 1880px !important;
             line-height: 20px;
             border-radius: 5px;
             background-color: rgba(56, 146, 235, 100);
             text-align: center;
             border: 1px solid rgba(255, 255, 255, 100);
             .box_one {
                 width: 1130px;
                 height: 1780px !important;
                 background-color: #fff;
                 line-height: 20px;
                 margin: 50px auto;
                 text-align: center;
                 border: 1px solid rgba(255, 255, 255, 100);
             }
         }
     }
     @media screen and (min-width: 1900px) and (max-width: 2100px) {
         .nei_box {
             overflow: hidden;
             margin: 30px auto;
             width: 1200px;
            //  height: 1900px !important;
             line-height: 20px;
             border-radius: 5px;
             background-color: rgba(56, 146, 235, 100);
             text-align: center;
             border: 1px solid rgba(255, 255, 255, 100);
             .box_one {
                 width: 1130px;
                 height: 1970px;
                 background-color: #fff;
                 line-height: 20px;
                 margin: 50px auto;
                 text-align: center;
                 border: 1px solid rgba(255, 255, 255, 100);
             }
         }
     }
    /deep/.el-form-item__label{
        height: 50px;
        line-height: 30px;
    }
    /deep/ .el-input__inner{
        height: 50px;
        line-height: 50px;
    }
    /deep/.el-select{
       display: unset;
    }
    /deep/.el-textarea__inner {
        resize: none;
        min-height: 65px!important;
    }
   .container{
        background-color: #F8F9FA;
        width: 100%;
        height: auto;
		margin-top: 136px;
       overflow: hidden;
        .nei_box{
            overflow: hidden;
            margin: 30px auto;
            width: 1200px;
            // height: 2100px;
            line-height: 20px;
            border-radius: 5px;
            background-color: rgba(56, 146, 235, 100);
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 100);
            .box_one{
                width: 1130px;
                height: 1970px;
                background-color: #fff;
                line-height: 20px;
                margin: 50px auto;
                text-align: center;
                border: 1px solid rgba(255, 255, 255, 100);
                .text_box{
                    text-align: left;
                    margin: 0px 0px 30px 230px;
                    .num_yan{
                        padding-right: 5px;
                        vertical-align: middle;
                        color: rgba(56, 146, 235, 100);
                        font-size: 36px;
                        text-align: left;
                    }
                    .num_text{
                        vertical-align: middle;
                        color: rgba(16, 16, 16, 100);
                        font-size: 18px;
                    }
                }
                .text_box_two{
                    text-align: left;
                    margin: 770px 0px 30px 230px;
                    .num_yan{
                        padding-right: 5px;
                        vertical-align: middle;
                        color: rgba(56, 146, 235, 100);
                        font-size: 36px;
                        text-align: left;
                    }
                    .num_text{
                        vertical-align: middle;
                        color: rgba(16, 16, 16, 100);
                        font-size: 18px;
                    }
                }
                .title_flex{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .text{
                        font-size: 28px;
                        color:#3892EB;
                        font-weight: 600;

                    }
                    .line{
                        margin:100px;
                        width: 60px;
                        height: 1px;
                        background-color: #3892EB ;
                    }
            }
                .form_box{
                    margin: 0 auto;
                    width: 613px;
                    height: 30px;
                }

            }
        }
    }
	@media screen and (max-width: 1200px) {
		.container{
			margin-top: 15rem;
		}
		.container .nei_box{
			width: 98%;
			height: auto;
		}
		.w{
			width: 98%;
		}
		.container .nei_box .box_one{
			width: 95%;
			height: 2000px;
		}
		.container .nei_box .box_one .text_box{
			margin-left: 0;
		}
		.container .nei_box .box_one .text_box_two{
			margin-left: 0;
		}
		.container .nei_box .box_one .form_box{
			width: 95%;
		}
		.container .nei_box .box_one .text_box .num_yan{
			font-size: 2rem;
		}
		.container .nei_box .box_one .text_box .num_text{
			font-size: 2rem;
		}
		.container .nei_box .box_one .text_box .text_box_yan{
			font-size: 1.4rem;
			line-height: 2rem;
		}
		/deep/.el-form-item__label{
			font-size: 1.4rem;
			width: 8rem !important;
		}
		/deep/.el-form-item__content{
			margin-left: 8rem !important;
			font-size: 1.4rem;
			margin: 0;
		}
		/deep/.el-input{
			font-size: 1.4rem;
		}
		.container .nei_box .box_one .text_box_two .num_yan{
			font-size: 2rem;
		}
		.container .nei_box .box_one .text_box_two .num_text{
			font-size: 2rem;
		}
		.container .nei_box .box_one .title_flex .text{
			font-size: 2.2rem
		}
		
		/deep/.el-button{
			font-size: 1.6rem;
			margin: 0 !important;
		}
	}
</style>